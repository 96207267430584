<!--
 * @Date: 2021-03-02 18:45:59
 * @LastEditors: frank
 * @LastEditTime: 2021-04-01 19:31:20
 * @FilePath: /shop_frontend/src/views/pageDecoration/shop.vue
 * @Description: Description
-->
<template>
  <section class="shop">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item ref="name" label="店铺名称" prop="name">
        <a-input
          v-model="form.name"
          @blur="
            () => {
              $refs.name.onFieldBlur();
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="notice" label="店铺公告" prop="notice">
        <a-input
          v-model="form.notice"
          @blur="
            () => {
              $refs.notice.onFieldBlur();
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item label="背景图片">
        <a-upload
          name="image"
          list-type="picture-card"
          :show-upload-list="false"
          action="/api/sign_pictures/"
          :before-upload="beforeUpload"
          :withCredentials="true"
          :headers="headers"
          @change="uploadChange"
        >
          <img style="display: inline-block; width: 375px; height: 210px;" v-if="imageUrl" :src="imageUrl" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
        <span style="color: red;">建议上传图片尺寸375px*210px</span>
      </a-form-model-item>
      <a-form-model-item label="当前排序">
        <a-input v-model="form.index" class="w220" />
      </a-form-model-item>
      <!-- <a-form-model-item label="是否显示">
        <a-checkbox v-model="form.is_visible"></a-checkbox>
      </a-form-model-item> -->
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
        <a-button type="danger" @click="onDelete" style="margin-left: 10px;"> 删除 </a-button>
      </a-form-model-item>
    </a-form-model>
  </section>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      form: {
        name: "",
        notice: "",
        picture_url: "",
        picture: "",
        is_visible: false,
        index: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {},
      imageUrl: "",
      loading: false,
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  components: {},
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
  },
  watch: {
    info: {
      handler: function(newVal) {
        console.log(newVal, "---");

        this.form = Object.assign({}, this.form, newVal);
        this.imageUrl = this.form.picture_url;
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    onDelete() {
      this.$axios.delete("/sign_modules/" + this.form.id + "/").then(() => {
        this.$emit("refreshDel");
      });
    },
    onSubmit() {
      console.log(this.form, "form");
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$axios.put("/sign_modules/" + this.form.id + "/", this.form).then((res) => {
            console.log(res);
            this.$message.success("操作成功");
            this.$emit("refresh");
          });
          //   alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    uploadChange({ file }) {
      console.log(file);
      if (file.status == "done") {
        this.loading = false;
        this.imageUrl = file.response.image;
        this.form.picture = file.response.id;
      }
    },
    beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 2048;
      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(2048KB)，请修改后重新上传");
        return false;
      } else {
        this.loading = true;
        return true;
      }
    },
  },
};
</script>

<style scoped lang="less"></style>
